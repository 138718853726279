import React, { useState, useEffect } from "react";
import '../utils/styles.css';
import NavbarMenu from "./componentes/navbarMenu";
import HeroSection from "./componentes/heroSection";
import Features from "./componentes/features";
import Counter from "./componentes/counter";
import Timeline from "./componentes/process";
import Testimonials from "./componentes/testimonials";
import PortfolioMarquee from "./componentes/portfolio";
import ContactUs from "./componentes/contactUs";
import Footer from "./componentes/footer";
import Loader from "../lib/loader";

const Tubisnez = () => {
    const [isLoading, setIsloading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => setIsloading(false), 3000); // Simulacion de tiempo de carga 3seg
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <NavbarMenu />
                    <HeroSection />
                    <Features />
                    <Counter />
                    <Timeline />
                    <svg
                        aria-hidden="true"
                        viewBox="0 -1 1440 230"
                        className="wave-transition"
                        style={{ marginTop: "-15px", width: "100%" }}
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="#1E1E1E"
                            fillOpacity="1"
                            d="M0,192L120,170.7C240,149,480,107,720,112C960,117,1200,171,1320,197.3L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                        ></path>
                    </svg>
                    <div className="bg-[#1E1E1E]">
                        <Testimonials />
                        <PortfolioMarquee />
                        <ContactUs />
                        <Footer />
                    </div>
                </>
            )}
        </>
    );
}

export default Tubisnez;