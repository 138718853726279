import React, { useRef } from "react";
import { motion } from "framer-motion";
import fondoVideo from "../../utils/video/fondo.mp4";
import { FlipWords } from "../ui/flipWords";

const HeroSection = () => {
  const words = ["efectivas", "innovadoras", "creativas", "modernas"];
  const videoRef = useRef(null);

  const scrollToQuePodemosHacer = () => {
    const section = document.getElementById("que-podemos-hacer");
    if (section) section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section
      id="hero-section"
      className="relative hero h-screen flex items-center justify-center"
      aria-labelledby="hero-title"
    >
      {/* Video de fondo */}
      <video
        ref={videoRef}
        src={fondoVideo}
        autoPlay
        muted
        playsInline
        loop
        preload="auto"
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1]"
        aria-hidden="true"
      >
        Tu navegador no soporta el video. Por favor, actualízalo.
      </video>

      {/* Contenedor de texto */}
      <div className="flex flex-col justify-center items-center text-center px-4 relative z-10">
        {/* Título principal */}
        <div
          id="hero-title"
          className="text-4xl sm:text-xl md:text-6xl font-bold text-white mt-0 sm:mt-24 md:mt-40"
        >
          <h1>
            Transformamos ideas <br />
            en soluciones <br />
            <span className="text-4xl sm:text-5xl md:text-6xl ml-0">
              <FlipWords words={words} />
            </span>
          </h1>
        </div>

        {/* Subtítulo */}
        <div className="text-xl sm:text-2xl font-bold text-white mt-4 sm:ml-8 md:ml-14">
          <h2>Diseñamos y desarrollamos <br /> experiencias web únicas e innovadoras.</h2>
          <h3 className="text-lg font-normal mt-2">
            Lleva tu negocio al siguiente nivel en toda Colombia.
          </h3>
        </div>

        {/* Botón de acción */}
        <div className="mt-6">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={scrollToQuePodemosHacer}
            className="px-6 py-3 bg-white text-[#3456E6] rounded-full shadow hover:shadow-md transition-all duration-300 font-bold"
            aria-label="Haz tu cotización gratuita"
          >
            ¿Qué podemos hacer?
          </motion.button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
