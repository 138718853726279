import React from "react";
import { motion } from 'framer-motion';
import { cn } from '../../lib/utils';
import imgwa from '../../utils/img/what.webp';
import imgem from '../../utils/img/gmail.webp';
import { Facebook, Instagram, Tiktok, Youtube } from "react-bootstrap-icons";

const ContactUs = () => {
    return (
        <>
            <div className="text-center w-full h-auto pt-8">
                <h1 className="text-3xl md:text-5xl lg:text-5xl font-bold text-center text-white">
                    Contáctanos ¡Estamos aquí para ayudarte!
                </h1>
                <p className="font-semibold text-center mt-4 text-sm md:text-lg mx-4 text-white">
                    No dudes más, estamos solo a un mensaje de distancia.
                </p>
            </div>
            <div className="w-full h-auto flex flex-col items-center md:items-start py-10 px-4 md:px-10">
                <div className="flex flex-col md:flex-row justify-center items-center w-full gap-6 md:gap-4">
                    {/* WhatsApp Card */}
                    <motion.article
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                        aria-labelledby="whatsapp-title"
                        aria-describedby="whatsapp-desc"
                        className="w-full max-w-sm md:w-1/3"
                    >
                        <a
                            href="https://wa.me/573106014402?text=Hola, me interesan sus servicios."  // Número actualizado y mensaje "Hola"
                            target="_blank"  // Abrir en una nueva pestaña
                            className="w-full h-full block"
                            title="Escríbenos por WhatsApp"
                            rel="noopener noreferrer"
                        >
                            <div className={cn(
                                "group w-full cursor-pointer overflow-hidden relative card h-72 md:h-80 rounded-[25px] shadow-xl mx-auto flex flex-col justify-end border border-transparent dark:border-neutral-800",
                                "transition-all duration-500"
                            )}>
                                <div className="w-full h-1/2 overflow-hidden rounded-t-[25px]">
                                    <img
                                        src={imgwa}
                                        alt="Ícono de WhatsApp: Contáctanos por chat"
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <div className="flex-grow p-4">
                                    <h2 id="whatsapp-title" className="text-gray-50 text-lg md:text-xl font-bold ml-4 mt-4">
                                        WhatsApp
                                    </h2>
                                    <p id="whatsapp-desc" className="text-[#3456E6] text-sm md:text-base font-bold block ml-4 mt-10">
                                        Haz clic para chatear
                                    </p>
                                </div>
                            </div>
                        </a>
                    </motion.article>

                    {/* Gmail Card */}
                    <motion.article
                        initial={{ opacity: 0, y: 50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, ease: 'easeOut' }}
                        aria-labelledby="email-title"
                        aria-describedby="email-desc"
                        className="w-full max-w-sm md:w-1/3"
                    >
                        <a
                            href="mailto:tubisnez@gmail.com"
                            className="w-full h-full block"
                            title="Escríbenos por Email"
                            rel="noopener noreferrer"
                        >
                            <div className={cn(
                                "group w-full cursor-pointer overflow-hidden relative card h-72 md:h-80 rounded-[25px] shadow-xl mx-auto flex flex-col justify-end border border-transparent dark:border-neutral-800",
                                "transition-all duration-500"
                            )}>
                                <div className="w-full h-1/2 overflow-hidden rounded-t-[25px]">
                                    <img
                                        src={imgem}
                                        alt="Ícono de email: Contáctanos por email"
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <div className="flex-grow p-4">
                                    <h2 id="email-title" className="text-gray-50 text-lg md:text-xl font-bold ml-4 mt-4">
                                        Gmail
                                    </h2>
                                    <p id="email-desc" className="text-[#3456E6] text-sm md:text-base font-bold block ml-4 mt-10">
                                        Haz clic para enviar un correo
                                    </p>
                                </div>
                            </div>
                        </a>
                    </motion.article>
                </div>

                {/* Redes Sociales */}
                <nav className="w-full h-auto py-6 mt-8" aria-label="Redes sociales">
                    <div className="w-full max-w-screen-lg mx-auto grid grid-cols-2 sm:grid-cols-4 gap-4 md:gap-6">
                        {/* Instagram */}
                        <motion.a
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                            href="https://www.instagram.com/tubisnez"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Síguenos en Instagram"
                        >
                            <div className="flex flex-col items-center group p-4">
                                <Instagram className="text-white group-hover:text-[#E4405F] text-2xl md:text-3xl"/>
                                <span className="text-white text-sm md:text-base mt-2 group-hover:text-[#E4405F]">@tubisnez</span>
                            </div>
                        </motion.a>
                        {/* TikTok */}
                        <motion.a
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                            href="https://www.tiktok.com/@tubisnez"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Síguenos en TikTok"
                        >
                            <div className="flex flex-col items-center group p-4">
                                <Tiktok className="text-white group-hover:text-[#69C9D0] text-2xl md:text-3xl"/>
                                <span className="text-white text-sm md:text-base mt-2 group-hover:text-[#69C9D0]">@tubisnez</span>
                            </div>
                        </motion.a>
                        {/* Facebook */}
                        <motion.a
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                            href="https://www.facebook.com/tubisnez"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Síguenos en Facebook"
                        >
                            <div className="flex flex-col items-center group p-4">
                                <Facebook className="text-white group-hover:text-[#3B5998] text-2xl md:text-3xl" />
                                <span className="text-white text-sm md:text-base mt-2 group-hover:text-[#3B5998]">@tubisnez</span>
                            </div>
                        </motion.a>
                        {/* YouTube */}
                        <motion.a
                            initial={{ opacity: 0, y: 50 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                            href="https://www.youtube.com/@tubisnez"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Síguenos en YouTube"
                        >
                            <div className="flex flex-col items-center group p-4">
                                <Youtube className="text-white group-hover:text-[#FF0000] text-2xl md:text-3xl"/>
                                <span className="text-white text-sm md:text-base mt-2 group-hover:text-[#FF0000]">@tubisnez</span>
                            </div>
                        </motion.a>
                    </div>
                </nav>
            </div >
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "TuBisnez",
                    "url": "https://tubisnez.com",
                    "contactPoint": [
                        {
                            "@type": "ContactPoint",
                            "telephone": "+573106014402",
                            "contactType": "Customer Service",
                            "areaServed": "CO",
                            "availableLanguage": ["es", "en"]
                        },
                        {
                            "@type": "ContactPoint",
                            "email": "tubisnez@gmail.com",
                            "contactType": "Support",
                            "areaServed": "CO",
                            "availableLanguage": ["es", "en"]
                        }
                    ],
                    "sameAs": [
                        "https://www.instagram.com/tubisnez",
                        "https://www.tiktok.com/@tubisnez",
                        "https://www.facebook.com/tubisnez",
                        "https://www.youtube.com/@tubisnez"
                    ]
                })}
            </script>

        </>
    );
}

export default ContactUs;