import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/effect-creative';
import { EffectCreative, Autoplay } from 'swiper/modules';
import crisha from '../../utils/img/crishacaps.webp';
import distrimark from '../../utils/img/distrimarkplus.webp';

const projects = [
  { id: 1, title: "CrishaCaps", image: crisha, link: 'https://www.crishacaps.com' },
  { id: 2, title: "Distrimark Plus", image: distrimark, link: 'https://www.distrimarkplus.com/' },
];

const PortfolioMarquee = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Detectar si el dispositivo es móvil
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    };

    handleResize(); // Ejecutar al cargar
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <section className="pb-8 px-4 sm:px-8 lg:px-16 overflow-hidden" id="portafolio">
        <h2 className="text-3xl md:text-5xl lg:text-5xl font-bold text-center text-white">
          Proyectos Destacados de Diseño Web
        </h2>
        <p className="font-semibold text-center mt-4 text-sm md:text-lg mx-4 mb-8 text-white">
          Explora los proyectos destacados que hemos desarrollado en diseño web, branding y soluciones digitales para empresas en Colombia y el mundo.
        </p>

        {isMobile ? (
          // Swiper para dispositivos móviles
          <Swiper
            spaceBetween={16} // Espaciado entre slides
            slidesPerView={1} // Mostrar solo un slide por página
            grabCursor={true}
            effect={'creative'}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            loop={true}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -400],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            modules={[EffectCreative, Autoplay]}
            className="w-full max-w-screen overflow-hidden mx-auto"
          >
            {projects.map((project, index) => (
              <SwiperSlide key={`project-${index}`}>
                <motion.div
                  className="project-card bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300 w-full"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.5,
                    delay: index * 0.1,
                  }}
                >
                  <img
                    src={project.image}
                    alt={project.title}
                    className="w-full h-50 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold text-gray-800">
                      {project.title}
                    </h3>
                    <a
                      href={project.link}
                      className="text-[#3456E6] font-semibold mt-2 inline-block relative group hover:text-blue-600 transition-colors duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver proyecto
                      <span className="absolute left-0 bottom-0 w-0 h-[2px] bg-blue-600 transition-all duration-300 group-hover:w-full"></span>
                    </a>
                  </div>
                </motion.div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          // Lista tradicional para computadoras
          <div className="marquee-content flex gap-6 justify-center flex-wrap">
            {projects.map((project, index) => (
              <motion.article
                key={`project-${index}`}
                aria-labelledby={`project-title-${index}`}
                aria-describedby={`project-desc-${index}`}
                className="project-card bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-shadow duration-300 w-64 flex-shrink-0"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.1,
                }}
              >
                <img
                  src={project.image}
                  alt={`Captura de pantalla del ${project.title}`}
                  className="w-full h-50 object-cover"
                />
                <div className="p-4" id={`project-title-${index}`}>
                  <h3 className="text-lg font-semibold text-gray-800">
                    {project.title}
                  </h3>
                  <a
                    href={project.link}
                    className="text-[#3456E6] font-semibold mt-2 inline-block relative group hover:text-blue-600 transition-colors duration-300"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ver proyecto
                    <span className="absolute left-0 bottom-0 w-0 h-[2px] bg-blue-600 transition-all duration-300 group-hover:w-full"></span>
                  </a>
                </div>
              </motion.article>
            ))}
          </div>
        )}
      </section>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "ItemList",
          "name": "Portafolio de Proyectos de Diseño Web",
          "description": "Explora nuestro portafolio de diseño web y desarrollo digital en Colombia. Proyectos destacados que marcan la diferencia.",
          "itemListElement": projects.map((project, index) => ({
            "@type": "ListItem",
            "position": index + 1,
            "url": `/proyectos/${project.id}`,
            "name": project.title,
            "image": project.image,
          })),
        })}
      </script>
    </>
  );
};

export default PortfolioMarquee;