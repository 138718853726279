"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import TestimonialsUi from "../ui/animatedTestimonials";

const Testimonials = () => {
    const [inView, setInView] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.getElementById("testimonials-container");
            if (element) {
                const rect = element.getBoundingClientRect();
                if (rect.top < window.innerHeight * 0.8) {
                    setInView(true);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Para verificar la visibilidad en la carga inicial

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <motion.div
            id="testimonials-container"
            initial={{ y: 100, opacity: 0 }}
            animate={inView ? { y: 0, opacity: 1 } : {}}
            transition={{ duration: 1.2, ease: "easeOut" }}
            className="main-container"
        >
            {/* Contenedor de testimonios */}
            <section aria-labelledby="testimonials-title" className="w-full">
                <h4 className="text-3xl md:text-5xl lg:text-5xl font-bold text-center text-white" id="testimonials-title">
                    Lo Que Dicen Nuestros Clientes
                </h4>
                <p className="font-semibold text-center mt-4 text-sm md:text-lg mx-4 text-white">
                    Nuestros clientes confían en nosotros para llevar sus negocios al mundo digital. Aquí tienes algunas historias de éxito
                </p>
                <div className="max-w-lg md:max-w-4xl mx-auto px-4 md:px-8 lg:px-12 py-8 md:py-20">
                    <TestimonialsUi />
                </div>
            </section>
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "ItemList",
                    "name": "Testimonios de Clientes sobre Diseño y Desarrollo Web",
                    "itemListElement": [
                        {
                            "@type": "Review",
                            "author": "Cristhian Burbano",
                            "datePublished": "2024-09-15",
                            "reviewBody": "Trabajar con ellos es una gran experiencia, tanto personal como Profesional, son personas capaces y muy atentas a todas tus necesidades como emprendedor. Han sido unos excelentes aliados en nuestro desarrollo como empresa",
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5",
                                "bestRating": "5"
                            }
                        },
                        {
                            "@type": "Review",
                            "author": "Javier Burbano",
                            "datePublished": "2024-09-30",
                            "reviewBody": "Crearon una pagina web perfecta para nuestro negocio. El proceso fue sencillo, me hicieron participe del mismo y el resultado superó todas mis expectativas.",
                            "reviewRating": {
                                "@type": "Rating",
                                "ratingValue": "5",
                                "bestRating": "5"
                            }
                        }
                    ]
                })}
            </script>
        </motion.div>
    );
};

export default Testimonials;
